import Menu from "../components/Menu"
import Header from "../components/Header"
import headerImage from '../images/webappheader.png'
import SectionWeb from "../components/SectionWeb"
import Contact from "../components/Contact";
import Footer from "../components/Footer"
import SectionWeb2 from "../components/SectionWeb2";
import webImage from '../images/logo.png'
import appImage from '../images/app.png'
import { useRef } from "react";
import { useLocation } from "react-router-dom";

export default function DevWeb() {
    const location = useLocation()
    const pathSegments = location.pathname.split("/");
    const lang = pathSegments.length > 1 ? pathSegments[1] : "";

    const sectionWebRef = useRef(null)

    let slogan
    if (lang === "fr" || lang === "en") {
      slogan = "Dev. Web & Apps"
    } 


    const scrollToServices = (e) => {
        e.preventDefault();
        if (sectionWebRef.current) {
            sectionWebRef.current.scrollIntoView({ behavior: "smooth" });
        }
      };
    


    return (
        <Menu language={lang}>
            <Header isButton={false} slogan={slogan} headerImage={headerImage} language={lang} />
            <SectionWeb image={webImage} language={lang} />
            <SectionWeb2 image={appImage} scrollToServices={scrollToServices} language={lang}  />
            <Contact ref={sectionWebRef} language={lang} />
            <Footer language={lang} />
        </Menu>
    )
}