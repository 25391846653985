import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import ErrorPage from './pages/Error';
import Immobilier from './pages/Immobiler';
import Data from './pages/Data';
import DevWeb from './pages/DevWeb';
import NosSolutions from './pages/NosSolutions';
import Blog from './pages/Blog';
import Article from './pages/Article';

const router = createBrowserRouter([
  {
    path: '/fr',
    element: <Home />,
    errorElement: <ErrorPage />,
    // action: sendMessage,
  },
  {
    path: '/fr/immobilier',
    element: <Immobilier />,
    errorElement: <ErrorPage />,
    // action: sendMessage,
  },
  {
    path: '/fr/data',
    element: <Data />,
    errorElement: <ErrorPage />,
    // action: sendMessage,
  },
  {
    path: '/fr/web-development',
    element: <DevWeb />,
    errorElement: <ErrorPage />,
    // action: sendMessage,
  },
  {
    path: '/fr/solutions',
    element: <NosSolutions />,
    errorElement: <ErrorPage />,
    // action: sendMessage,
  },
  {
    path: '/fr/blog',
    element: <Blog />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/fr/blog/:id',
    element: <Article />,
    errorElement: <ErrorPage />,
    // action: sendMessage,
  },
  {
    path: '/en',
    element: <Home />,
    errorElement: <ErrorPage />,
    // action: sendMessage,
  },
  {
    path: '/en/immobilier',
    element: <Immobilier />,
    errorElement: <ErrorPage />,
    // action: sendMessage
  },
  {
    path: '/en/data',
    element: <Data />,
    errorElement: <ErrorPage />,
    // action: sendMessage,
  },
  {
    path: '/en/web-development',
    element: <DevWeb />,
    errorElement: <ErrorPage />,
    // action: sendMessage,
  },
  {
    path: '/en/solutions',
    element: <NosSolutions />,
    errorElement: <ErrorPage />,
    // action: sendMessage,
  },
  {
    path: '/en/blog',
    element: <Blog />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/en/blog/:id',
    element: <Article />,
    errorElement: <ErrorPage />,
    // action: sendMessage,
  },
  {
    path: '/ar',
    element: <Home />,
    errorElement: <ErrorPage />,
    // action: sendMessage,
  },
  {
    path: '/ar/immobilier',
    element: <Immobilier />,
    errorElement: <ErrorPage />
  },
  {
    path: '/ar/data',
    element: <Data />,
    errorElement: <ErrorPage />,
    // action: sendMessage,
  },
  {
    path: '/ar/web-development',
    element: <DevWeb />,
    errorElement: <ErrorPage />,
    // action: sendMessage,
  },
  {
    path: '/ar/solutions',
    element: <NosSolutions />,
    errorElement: <ErrorPage />,
    // action: sendMessage,
  },
])

function App() {
  return <RouterProvider router={router} />;
}

export default App;