import classes from "./SectionImmo.module.css"
import ImmoPic1 from '../images/immoPic1.png'


export default function SectionImmo(props) {
    // const listImages = [["Ulis", UlisLogo, classes.image_0], 
    // ["Ikos", IkosLogo, classes.image_1],
    // ["Cassiopae", CassiopaeLogo, classes.image_2]] 
    const listImages = [["ImmoPic1", ImmoPic1, classes.image_0]] 

    let text
    if (props.language === "fr") {
        text =  <div className={classes.blocText}>
        <h2 className={classes.sectionsubtitle}>Notre Expertise en Support des Logiciels de Gestion Immobilière</h2>
        <p className={classes.texte}>
        Chez MBE & Consult, notre engagement envers l'excellence s'étend à toutes les facettes de l'industrie immobilière, y compris la gestion locative, financière et copropriété. Nous sommes fiers d'offrir une expertise inégalée dans le support et l'optimisation des logiciels de gestion immobilier. 
        </p>

        <p className={classes.texte}>
        La gestion locative est une composante cruciale de l'industrie immobilière, et nous comprenons les défis complexes auxquels sont confrontés les professionnels de la gestion immobilière au quotidien. C'est pourquoi nous nous sommes engagés à fournir des solutions de support logiciel de la plus haute qualité pour répondre à vos besoins uniques.       
        </p>
        <p className={classes.texte}>
        Notre équipe expérimentée est composée d'experts en ERP qui maîtrisent en profondeur le métier de l'immobilier. Nous sommes en mesure de vous guider à travers toutes les étapes, de l'installation initiale à la personnalisation avancée, en passant par la résolution rapide des problèmes et la formation de votre personnel.    
        </p>

        <p className={classes.texte}>
        Notre approche de support logiciel est centrée sur le client. Nous comprenons que chaque entreprise immobilière a des exigences spécifiques en matière de gestion. C'est pourquoi nous travaillons en étroite collaboration avec vous pour adapter nos services à vos besoins précis, que vous gériez un portefeuille résidentiel, commercial ou mixte.
        </p>

        <p className={classes.texte}>
        Que vous souhaitiez optimiser l'efficacité opérationnelle, améliorer la précision des données, gérer des interfaces avec vos différents produits (comptabilité générale comme AGRESSO, QUALIAC etc., maintenance de patrimoine et réclamations) ou intégrer de nouvelles fonctionnalités, notre équipe est là pour vous accompagner à chaque étape du processus. Notre objectif est de vous permettre de tirer le meilleur parti de vos logiciels de gestion locative, afin que vous puissiez vous concentrer sur la croissance de votre entreprise et la satisfaction de vos locataires.            
        </p>

        <p className={classes.texte}>
        En choisissant MBE & Consult comme partenaire de confiance pour le support de vos logiciels de gestion locative, vous optez pour l'excellence, l'innovation et le service personnalisé. Nous sommes déterminés à vous aider à réussir dans l'industrie immobilière en constante évolution, tout en maximisant la rentabilité de vos investissements.            
        </p>

        <a href="#" onClick={(e) => {props.scrollToServices(e)}} className={classes.button}>Contactez-Nous Aujourd'hui</a>

        <p className={classes.texte}>
        Faites équipe avec nous et découvrez comment notre expertise peut transformer votre gestion locative de manière significative. Contactez-nous dès aujourd'hui pour discuter de vos besoins spécifiques et découvrir comment nous pouvons vous aider à atteindre vos objectifs.           
        </p> 
        </div>  
    } else if (props.language === "en") {
        text =  <div className={classes.blocText}>
        <h2 className={classes.sectionsubtitle}>Our Expertise in Rental Management Software Support</h2>
        <p className={classes.texte}>
        At MBE & Consult, our commitment to excellence extends to all facets of the real estate industry, including rental, financial and co-ownership management. We are proud to offer unrivaled expertise in the support and optimization of rental management software.        
        </p>

        <p className={classes.texte}>
        Property management is a crucial component of the real estate industry, and we understand the complex challenges property management professionals face every day. That's why we're committed to providing the highest quality software support solutions to meet your unique needs.        
        </p>
        <p className={classes.texte}>
        Our experienced team is made up of rental management software experts who have an in-depth understanding. We are able to guide you through every step, from initial installation to advanced customization, rapid problem resolution and training of your staff.        
        </p>

        <p className={classes.texte}>
        Our software support approach is customer-centric. We understand that each real estate business has specific rental management requirements. That's why we work closely with you to tailor our services to your precise needs, whether you manage a residential, commercial or mixed portfolio.        
        </p>

        <p className={classes.texte}>
        Whether you want to optimize operational efficiency, improve data accuracy, or integrate new features, our team is here to support you every step of the way. Our goal is to help you get the most out of your rental management software, so you can focus on growing your business and keeping your tenants happy.        
        </p>

        <p className={classes.texte}>
        By choosing MBE & Consult as a trusted partner for the support of your rental management software, you are opting for excellence, innovation and personalized service. We are committed to helping you succeed in the ever-changing real estate industry, while maximizing the return on your investments.        
        </p>

        <a href="#" onClick={(e) => {props.scrollToServices(e)}} className={classes.button}>Contact Us Today</a>

        <p className={classes.texte}>
        Team up with us and discover how our expertise can significantly transform your rental management. Contact us today to discuss your specific needs and find out how we can help you achieve your goals.        
        </p> 
        </div>  
    }


    return (
        <section className={classes.section}>
            <div className={classes.blocImages}>
                {listImages.map((item, number) => {
                    return <img key={item[0]} src={item[1]} alt={item[0]} className={item[2]} />
                })}
            </div>
            {text}
        </section>

    )
}