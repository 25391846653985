import { useRouteError } from 'react-router-dom';

function ErrorPage() {
  const error = useRouteError();

  let message;

  if (error.status === 500) {
    message = error.data.message;
  }
  if (error.status === 200) {
    message = error.data.message;
  }

  return (
    <>  
    <p>{message}</p>
    </>
  );
}

export default ErrorPage;
