import classes from "./Section1.module.css"
import reportingLogo1 from '../images/reportingLogo1.png'
import reportingLogo2 from '../images/reportingLogo2.png'
import reportingLogo3 from '../images/reportingLogo3.png'

export default function Section1(props) {
    const listImages = [["reportingLogo1", reportingLogo1, classes.image_0], 
    ["reportingLogo2", reportingLogo2, classes.image_1],
    ["reportingLogo3", reportingLogo3, classes.image_2]] 

    let text
    if (props.language === "fr") {
        text =  <div className={classes.blocText}>
        <h2 className={classes.sectionsubtitle}>Optimisez Votre Stratégie avec Notre Expertise en Analyse de Données et Reportings</h2>
        <p className={classes.texte}>
            Chez MBE & CONSULT, nous comprenons que chaque décision commerciale doit être prise en s'appuyant sur des informations précises et exploitables. C'est pourquoi nous nous engageons à offrir à nos clients une expertise inégalée en analyse de données et en reportings pour les aider à maximiser leur succès.
        </p>
        <h2 className={classes.sectionsubtitle}>Une Approche Axée sur les Données</h2> 
        <p className={classes.texte}>
            Notre équipe d'experts chevronnés en analyse de données sait que les données sont la pierre angulaire de toute entreprise moderne. Nous commençons par collecter, nettoyer et organiser vos données de manière à ce qu'elles soient prêtes à être exploitées. Que vous ayez besoin d'analyser les ventes, les performances marketing, la satisfaction des clients ou tout autre aspect de votre activité, nous sommes prêts à relever le défi.
        </p>
        <h2 className={classes.sectionsubtitle}>Des Insights Puissants</h2> 
        <p className={classes.texte}>
            L'analyse des données ne consiste pas seulement à collecter des chiffres, mais à extraire des informations significatives. Notre équipe utilise des techniques avancées d'analyse statistique, de data mining et de machine learning pour découvrir des tendances cachées et des opportunités inexploitées. Les insights que nous obtenons vous permettent de prendre des décisions éclairées et de développer une stratégie gagnante.
        </p>
        <h2 className={classes.sectionsubtitle}>Des Reportings Personnalisés</h2> 
        <p className={classes.texte}>
            Chaque entreprise est unique, c'est pourquoi nous personnalisons nos reportings pour répondre à vos besoins spécifiques. Nous créons des tableaux de bord interactifs, des rapports visuels et des analyses approfondies qui vous donnent une vue complète de la santé de votre entreprise. Nos reportings vous permettent de suivre vos KPIs, d'évaluer les performances de vos initiatives et d'ajuster votre stratégie en temps réel.
        </p>
        <h2 className={classes.sectionsubtitle}>La Clé de Votre Réussite</h2> 
        <p className={classes.texte}>
            Chez MBE & CONSULT, nous considérons que l'analyse de données et les reportings sont la clé de votre réussite. En collaborant avec nous, vous avez accès à une expertise de pointe qui vous donne un avantage concurrentiel. Nos solutions vous aident à identifier des opportunités de croissance, à réduire les coûts et à améliorer l'efficacité opérationnelle.
        </p>
        <a href="#" onClick={(e) => {props.scrollToServices(e)}} className={classes.button}>Contactez-Nous Aujourd'hui</a>
        <p className={classes.texte}>
            Si vous souhaitez exploiter tout le potentiel de vos données et bénéficier d'une expertise en analyse de données et reportings de premier ordre, n'hésitez pas à nous contacter. Chez MBE & CONSULT, nous sommes prêts à vous aider à transformer vos données en action.                
        </p>
        </div>
    } else if (props.language === "en") {
        text =  <div className={classes.blocText}>
        <h2 className={classes.sectionsubtitle}>Optimize Your Strategy with Our Expertise in Data Analysis and Reporting</h2>
        <p className={classes.texte}>
        At MBE & CONSULT, we understand that every business decision must be made based on accurate, actionable information. That's why we are committed to providing our clients with unparalleled expertise in data analysis and reporting to help them maximize their success.        
        </p>
        <h2 className={classes.sectionsubtitle}>A Data-Driven Approach</h2> 
        <p className={classes.texte}>
        Our team of seasoned data analytics experts understand that data is the lifeblood of any modern business. We start by collecting, cleaning and organizing your data so that it is ready to use. Whether you need to analyze sales, marketing performance, customer satisfaction, or any other aspect of your business, we're up for the challenge.        
        </p>
        <h2 className={classes.sectionsubtitle}>Des Insights Puissants</h2> 
        <p className={classes.texte}>
        Data analysis is not just about collecting numbers, but extracting meaningful information. Our team uses advanced statistical analysis, data mining and machine learning techniques to uncover hidden trends and untapped opportunities. The insights we obtain allow you to make informed decisions and develop a winning strategy.        
        </p>
        <h2 className={classes.sectionsubtitle}>Personalized Reports</h2> 
        <p className={classes.texte}>
        Every business is unique, which is why we customize our reporting to meet your specific needs. We create interactive dashboards, visual reports and in-depth analytics that give you a complete view of the health of your business. Our reporting allows you to monitor your KPIs, evaluate the performance of your initiatives and adjust your strategy in real time.        
        </p>
        <h2 className={classes.sectionsubtitle}>The Key to Your Success</h2> 
        <p className={classes.texte}>
        At MBE & CONSULT, we consider that data analysis and reporting are the key to your success. By collaborating with us, you have access to cutting-edge expertise that gives you a competitive advantage. Our solutions help you identify growth opportunities, reduce costs and improve operational efficiency.        
        </p>
        <a href="#" onClick={(e) => {props.scrollToServices(e)}} className={classes.button}>Contact Us Today</a>
        <p className={classes.texte}>
        If you want to exploit the full potential of your data and benefit from expertise in first-rate data analysis and reporting, do not hesitate to contact us. At MBE & CONSULT, we are ready to help you turn your data into action.        
        </p>
        </div>
    }

    return (
        <section className={classes.section}>
            {text}
            <div className={classes.blocImages}>
                {listImages.map((item, number) => {
                    return <img key={item[0]} src={item[1]} alt={item[0]} className={item[2]} />
                })}
            </div>
        </section>
    )
}