import Header from "../components/Header";
import Clients from "../components/Clients";
import Services from "../components/Services";
import Solutions from "../components/Solutions";
import Histoire from "../components/Histoire";
import Menu from "../components/Menu";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import { useRef } from "react";
import headerImage from '../images/header1.png'
import { useLocation } from "react-router-dom";

export default function Home() {
    const servicesRef = useRef(null)
    const location = useLocation()
    const pathSegments = location.pathname.split("/");
    const lang = pathSegments.length > 1 ? pathSegments[1] : "";

    let slogan
    if (lang === "fr") {
      slogan = "Votre partenaire numérique"
    } else if (lang === "en") {
      slogan = "Your digital partner"
    }

    const scrollToServices = (e) => {
        e.preventDefault();
        if (servicesRef.current) {
          servicesRef.current.scrollIntoView({ behavior: "smooth" });
        }
      };

    return (
        <>
        <Menu language={lang}>
          <Header scrollToServices={scrollToServices} isButton={true} slogan={slogan} headerImage={headerImage} language={lang} />
          <Clients language={lang} />
          <Services language={lang} ref={servicesRef} color="rgba(23, 34, 67, 1)"/>
          <Solutions language={lang} color="white" />
          <Histoire language={lang} />
          <Contact language={lang} />
          <Footer language={lang} />
        </Menu>
        </>
    )
}