import Menu from "../components/Menu"
import Header from "../components/Header"
import { useLocation } from "react-router-dom";
import headerImage from '../images/nossolutionsheader.png'
import BlocSolutions from "../components/BlocSolutions";
import Contact from "../components/Contact"
import Footer from "../components/Footer";
import { useRef } from "react";
import BlocSolutions2 from "../components/BlocSolutions2";

export default function NosSolutions() {
    const location = useLocation()
    const pathSegments = location.pathname.split("/");
    const lang = pathSegments.length > 1 ? pathSegments[1] : "";

    const blocSolutionsRef = useRef(null)

    let slogan
    if (lang === "fr" || lang === "en") {
      slogan = "Solutions"
    } 

    const scrollToServices = (e) => {
        e.preventDefault();
        if (blocSolutionsRef.current) {
          blocSolutionsRef.current.scrollIntoView({ behavior: "smooth" });
        }
      };


    return (
        <Menu language={lang} >
            <Header isButton={false} slogan={slogan} headerImage={headerImage} language={lang}  />
            <BlocSolutions scrollToServices={scrollToServices} language={lang}  />
            <BlocSolutions2 scrollToServices={scrollToServices} language={lang}  />
            <Contact ref={blocSolutionsRef} language={lang}  />
            <Footer language={lang}  />
        </Menu>
    )
}