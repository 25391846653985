import { useState } from "react"

export default function useContact(validateElement) {
    const [element, setElement] = useState("")
    const [isTouched, setIsTouched] = useState(false)
    const handleLoseFocus = () => {
        setIsTouched(true)
    }
    
    const elementValid = validateElement(element);

    const onChange = (event) => {
        setElement(event.target.value)
    }

    const resetElement = () => {
        setElement("")
        setIsTouched(false)
    }

    return {
        handleLoseFocus, 
        resetElement, onChange, elementValid, isTouched, element
    }
}