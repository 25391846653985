import classes from "./Header.module.css";
// import Carousel from "./Carousel";
export default function Header(props) {
  let messageButton
  if (props.language === "fr") {
    messageButton = "Découvrez nos services et produits"
  } else if (props.language === "en") {
    messageButton = "Discover our services and products"
  }
  return (
    <div className={classes.header} style={{ backgroundImage: `linear-gradient(to right bottom, rgba(255, 255, 255, 0.8), rgba(25, 64, 86, 0.8)), url(${props.headerImage})`}}>
        <div className={classes.texte}>
            <h1>
                <span className={classes["main-title"]}>MBE & CONSULT</span>
                <span className={classes.slogan}>{props.slogan}</span>
            </h1>
            {props.isButton && <a href="#" onClick={(e) => {props.scrollToServices(e)}} className={classes.button}>{messageButton}</a>}
        </div>
      {/* <Carousel /> */}
    </div>
  );
}
