import { useEffect, useState, useRef } from "react"
import CardPost from "./CardPost";
import classes from "./BlogPosts.module.css"
import { Link } from "react-router-dom";
import Spinner from "../components/Spinner";

export default function BlogPosts(props) {
    // const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    const [counter, setCounter] = useState(3)
    const [lengthData, setLengthData] = useState(0)
    const contentRef = useRef(null);
    
    let categoryId
    let moreArticles
    if (props.language === "fr") {
        categoryId = "2"
        moreArticles = "Voir plus d'articles"
      } else if (props.language === "en") {
        categoryId = "3"
        moreArticles = "See more articles"
      }
  

    useEffect(() => {
        // setLoading(true)
        async function fetchData() {
            try {
                const response = await fetch(`https://blog.mbe-consult.fr/wp-json/wp/v2/posts?categories=${categoryId}`);
                // setLoading(false)
                if (!response.ok) {
                  throw new Error({ message: "Désolé, une erreur s'est produite. Veuillez réessayer" }, { status: response.status });
                }
                const dataReceived = await response.json();
                const fullData = []
                for (let datum of dataReceived) {
                    if (datum.featured_media) {
                        let mediaResponse = await fetch(`https://blog.mbe-consult.fr/wp-json/wp/v2/media/${datum.featured_media}`);
                        let mediaData = await mediaResponse.json();
                        fullData.push({post:datum, image: mediaData})
                    }
                }
                setLengthData(fullData.length)
                setData(fullData)
              } catch (error) {
                // setLoading(false)
                throw new Error(error);
            }      
        }
        fetchData()
      }, []);

      const loadMoreArticles = () => {
        setCounter(prev => prev + 3)
        if (contentRef.current) {
            contentRef.current.scrollIntoView({ behavior: 'smooth' });
          }
      }

      function scrollToTop() {
        window.scrollTo(0, 0);
      }
    return (
        <section className={classes.section}>
            <div className={classes.cards}>
            {data ? data.slice(0, counter).map(post => {
                return <Link to={`/${props.language}/blog/${post.post.id}`} onClick={scrollToTop} className={classes.link} key={post.post.id}><CardPost title={post.post.title.rendered} excerpt={post.post.excerpt.rendered} image={post.image.source_url} altText={post.image.alt_text} /></Link> 
            }) : <Spinner/> }
            </div>
            {counter < lengthData ? <button className={classes.button} onClick={loadMoreArticles} ref={contentRef}>{moreArticles}</button> : ""}
        </section>
    )
}