import classes from "./CardPost.module.css"
import parse from 'html-react-parser'

export default function CardPost(props) {
    // function removeHtmlTags(input) {
    //     return input.replace(/<[^>]*>/g, '');
    //   }

    const options = {
    decodeEntities: true, // Add this option to decode HTML entities
    };

    return (
        <div className={classes.card}>
            <img src={props.image} alt={props.altText} className={classes.image} />
            <div className={classes.blocText}>
                <h2 className={classes.title}>{parse(props.title, options)}</h2>
                <span className={classes.excerpt}>{parse(props.excerpt, options)}</span>
            </div>
        </div>
    )
}