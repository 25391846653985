import classes from "./Services.module.css";
import Immo from "../images/immo.png";
import Data from "../images/data.png";
import WepApps from "../images/web.png";
import Bloc from "./Bloc";
import { forwardRef } from "react";
import { Link, useLocation } from "react-router-dom";

const Services = forwardRef((props, ref) => {
  const location = useLocation();
  let data
  let ServicesText = "Nos Services"
  if (props.language === "fr") {
    ServicesText = "Nos services"
    data = [
      ["Immobilier", Immo, "/immobilier"],
      ["Data", Data, "/data"],
      ["Dev. Web & Apps", WepApps, "/web-development"],
    ];
  } else if (props.language === "en") {
    ServicesText = "Our services"
    data = [
      ["Real Estate", Immo, "/immobilier"],
      ["Data", Data, "/data"],
      ["Dev. Web & Apps", WepApps, "/web-development"],
    ];
  }


  function scrollToTop() {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }

  return (
    <div ref={ref} className={classes.services} style={{ backgroundImage: "linear-gradient(to right bottom, rgba(23, 34, 67, 1), rgba(23, 34, 67, 0.95))" }}>
      <h2 className={classes.servicesTxt}>{ServicesText}</h2>
      <div className={classes.blocs}>
        {data.map((item) => {
          return <Link to={location.pathname + item[2]} onClick={scrollToTop} key={item[0]}><Bloc className={classes.bloc} img={item[1]} txt={item[0]} color="linear-gradient(to right bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.95))" /></Link>;
        })}
      </div>
    </div>
  );
})

export default Services