import classes from './Footer.module.css'
import logo from '../images/logo-white.png'
import { Link } from 'react-router-dom'

export default function Footer(props) {

    let menuList = []

    if (props.language === "fr") {
        menuList = [["Accueil", ""], ["Immobilier", "/immobilier"], ["Data", "/data"], ["Dev Web & Apps", "/web-development"], ["Nos solutions", "/solutions"], ["Blog", "/blog"]]
    } else if (props.language === "en") {
        menuList = [["Home", ""], ["Real Estate", "/immobilier"], ["Data", "/data"], ["Dev Web & Apps", "/web-development"], ["Our solutions", "/solutions"], ["Blog", "/blog"]]
    }

    const languesList = [["Français", '/fr'], ["English", "/en"]] //, ["العربية", "/ar"]

    function scrollToTop() {
        window.scrollTo(0, 0);
      }
    
    return (
        <>
            <div className={classes.footer} style={{ backgroundImage: "linear-gradient(to right bottom, rgba(23, 34, 67, 1), rgba(23, 34, 67, 0.95))" }}>
        <div className={classes.logoFooter}>
            <img src={logo} alt="logo MBE & Consult" className={classes.logoPic} />
            <h1 className={classes.logoTexte}>MBE & CONSULT</h1>
        </div>
        <div className={classes.breakLine}></div>
        <div className={classes.menuFooter}>
            <ul className={classes.footerUl}>
            {menuList.map((item) => {
                        const path = `/${props.language}${item[1]}`;
                        return <li key={item[0]}><Link to={path} onClick={scrollToTop}>{item[0]}</Link></li>
                    }) }
            </ul>
        </div>
    </div>
    <div className={classes.langues}>
        <ul className={classes.languesUl}>
                {languesList.map((item) => {
                        return <li key={item[1]}><Link to={item[1]} onClick={scrollToTop}>{item[0]}</Link></li>
                    }) }
            </ul>
    </div>
    </>
    )
}