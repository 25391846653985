import classes from './SectionWeb2.module.css'

export default function SectionWeb2(props) {
    let text
    if (props.language === "fr") {
        text =  <div className={classes.containerTexte}>
        <h2 className={classes.sectionsubtitle}>Performances Optimales</h2>
        <p className={classes.sectionTexte}>La vitesse et la performance sont essentielles pour retenir les visiteurs. Nous optimisons chaque ligne de code, chaque image et chaque interaction pour garantir des temps de chargement rapides et une expérience fluide.</p>
        <h2 className={classes.sectionsubtitle}>Sécurité Robuste</h2>
        <p className={classes.sectionTexte}>La sécurité en ligne est cruciale. Nous mettons en place des mesures de sécurité robustes pour protéger vos données et celles de vos utilisateurs. Nous sommes vigilants pour détecter et contrer toute menace potentielle.</p>
        <h2 className={classes.sectionsubtitle}>Innovation Continue</h2>
        <p className={classes.sectionTexte}>Le monde du web évolue rapidement, et nous évoluons avec lui. Nous restons à l'affût des dernières technologies et tendances pour garantir que votre site web reste compétitif et à jour.</p>
        <h2 className={classes.sectionsubtitle}>Engagement envers la Qualité</h2>
        <p className={classes.sectionTexte}>La qualité est ancrée dans tout ce que nous faisons. Chaque ligne de code, chaque pixel et chaque interaction sont soigneusement testés et validés pour assurer un produit final de qualité exceptionnelle.</p>
        <h2 className={classes.sectionsubtitle}>Support Client Exceptionnel</h2>
        <p className={classes.sectionTexte}>Notre relation avec nos clients ne se termine pas à la livraison du site web. Nous offrons un support client exceptionnel pour répondre à toutes vos questions et besoins en cours d'exécution.</p>
        <a href="#" onClick={(e) => {props.scrollToServices(e)}} className={classes.button}>Contactez-Nous Aujourd'hui</a>
        <p className={classes.sectionTexte}>Confiez-nous votre projet de développement web, et découvrez comment notre expertise peut donner vie à votre vision en ligne. Chez MBE & CONSULT, nous sommes prêts à relever tous les défis et à créer des expériences web inoubliables.</p>
        </div>
    } else if (props.language === "en") {
        text = <div className={classes.containerTexte}>
        <h2 className={classes.sectionsubtitle}>Optimal Performance</h2>
        <p className={classes.sectionTexte}>Speed ​​and performance are essential to retain visitors. We optimize every line of code, every image, and every interaction to ensure fast load times and a smooth experience.</p>
        <h2 className={classes.sectionsubtitle}>Robust Security</h2>
        <p className={classes.sectionTexte}>Online security is crucial. We implement robust security measures to protect your data and that of your users. We are vigilant to detect and counter any potential threats.</p>
        <h2 className={classes.sectionsubtitle}>Continuous Innovation</h2>
        <p className={classes.sectionTexte}>The web world is evolving rapidly, and we are evolving with it. We stay on top of the latest technologies and trends to ensure your website stays competitive and up to date.</p>
        <h2 className={classes.sectionsubtitle}>Commitment to Quality</h2>
        <p className={classes.sectionTexte}>Quality is ingrained in everything we do. Every line of code, every pixel, and every interaction is carefully tested and validated to ensure a final product of exceptional quality.</p>
        <h2 className={classes.sectionsubtitle}>Exceptional Customer Support</h2>
        <p className={classes.sectionTexte}>Our relationship with our customers does not end with the delivery of the website. We offer exceptional customer support to answer all your running questions and needs.</p>
        <a href="#" onClick={(e) => {props.scrollToServices(e)}} className={classes.button}>Contact Us Today</a>
        <p className={classes.sectionTexte}>Entrust us with your web development project, and discover how our expertise can bring your vision to life online. At MBE & CONSULT, we are ready to take on any challenge and create unforgettable web experiences.</p>
        </div>
    }

    return (
        <section className={classes.section}>
            <img src={props.image} alt="web logo" className={classes.sectionImage} />
            {text}
        </section>
    )
}