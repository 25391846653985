import logo from '../images/logombeconsult (1).png'
import classes from './Menu.module.css'
import { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'

export default function Menu(props) {
    const [menuOpen, setMenuOpen] = useState(false)
    const [allowDisappear, setAllowDisappear] = useState(false)

    useEffect(() => {
        if (menuOpen) {
            setAllowDisappear(true)
        }
    }, [menuOpen])

    const appearBackground = menuOpen ? classes.appearBackground : classes.disappearBackground
    const appearMenu = menuOpen ? classes.appearMenu : classes.disappearMenu
    const changeLogo = menuOpen ? classes.appearLogo : classes.disappearLogo 

    let menuList
    if (props.language === "fr") {
        menuList = [["Accueil", ""], ["Immobilier", "/immobilier"], ["Data", "/data"], ["Dev Web & Apps", "/web-development"], ["Nos solutions", "/solutions"], ["Blog", "/blog"]]
    } else if (props.language === "en") {
        menuList = [["Home", ""], ["Real Estate", "/immobilier"], ["Data", "/data"], ["Dev Web & Apps", "/web-development"], ["Our solutions", "/solutions"], ["Blog", "/blog"]]
    }


    function scrollToTop() {
        window.scrollTo(0, 0); // Scroll to the top of the page
      }
    

    return (
        <>
            <img src={logo} alt="Logo" className={`${classes.logo} ${changeLogo}`} onClick={() => {setMenuOpen((prev) => !prev)}} />
            { allowDisappear &&
            <>
            <div className={`${classes.background} ${appearBackground}`}></div>
            <div className={`${classes.naviga} ${appearMenu}`}>
                <ul className={`${classes.menu}`} >
                    {menuList.map((item) => {
                        const path = `/${props.language}${item[1]}`;
                        return <li key={item[0]}><Link to={path} className={classes.link} onClick={scrollToTop}>{item[0]}</Link></li>
                    }) }
                </ul>
            </div>
            </>
            }
            {props.children}
        </>
    )
}