import classes from "./Section2.module.css"
import datascienceLogo1 from '../images/datasciencelogo1.png'
import datascienceLogo2 from '../images/datasciencelogo2.png'
import datascienceLogo3 from '../images/datasciencelogo3.png'

export default function Section2(props) {
    const listImages = [["datasciencelogo1", datascienceLogo1, classes.image_0], 
    ["datasciencelogo2", datascienceLogo2, classes.image_1],
    ["datasciencelogo3", datascienceLogo3, classes.image_2]] 

    let text
    if (props.language === "fr") {
        text =  <div className={classes.blocText}>
        <h2 className={classes.sectionsubtitle}>Transformez Votre Entreprise avec Notre Expertise en Data Science</h2>
        <p className={classes.texte}>
        Chez MBE & CONSULT, nous comprenons que les données sont le trésor caché de toute entreprise moderne. C'est pourquoi nous sommes fiers de vous offrir notre expertise de pointe en Data Science, y compris le Machine Learning et le Deep Learning, pour vous aider à transformer vos données en opportunités et à prendre des décisions éclairées.            
        </p>
        <h2 className={classes.sectionsubtitle}>Machine Learning et Prédictions Intelligentes</h2> 
        <p className={classes.texte}>
        Le Machine Learning est au cœur de ce que nous faisons. Nous développons des modèles prédictifs puissants qui vous aident à anticiper les tendances, à prendre des décisions basées sur les données et à optimiser vos opérations. Que ce soit pour la recommandation de produits, la détection de fraudes, l'analyse de sentiments ou toute autre application, nous avons l'expertise nécessaire pour vous guider.            
        </p>
        <h2 className={classes.sectionsubtitle}>Le Futur avec le Deep Learning</h2> 
        <p className={classes.texte}>
        Le Deep Learning représente l'avenir de l'intelligence artificielle. Nos experts en Deep Learning sont à la pointe de cette technologie révolutionnaire. Nous concevons des réseaux de neurones profonds qui sont capables d'apprendre à partir de données massives et complexes, ouvrant ainsi la porte à des avancées majeures dans la reconnaissance d'images, la compréhension du langage naturel et bien plus encore.            
        </p>
        <h2 className={classes.sectionsubtitle}>Des Solutions Personnalisées</h2> 
        <p className={classes.texte}>
        Chez MBE & CONSULT, nous savons que chaque entreprise est unique. C'est pourquoi nous créons des solutions personnalisées en Data Science qui répondent à vos besoins spécifiques. Que vous soyez une start-up cherchant à utiliser les données pour prendre un bon départ ou une grande entreprise cherchant à innover, nous avons la solution qui vous convient.            
        </p>
        <a href="#" onClick={(e) => {props.scrollToServices(e)}} className={classes.button}>Contactez-Nous Aujourd'hui</a>
        <p className={classes.texte}>
        Si vous êtes prêt à exploiter la puissance des données, du Machine Learning et du Deep Learning pour stimuler la croissance de votre entreprise, nous sommes là pour vous aider. Chez MBE & CONSULT, nous sommes passionnés par la Data Science, et nous sommes prêts à travailler avec vous pour réaliser votre vision.            
        </p>
        </div>
    } else if (props.language === "en") {
        text = <div className={classes.blocText}>
        <h2 className={classes.sectionsubtitle}>Transform Your Business with Our Data Science Expertise</h2>
        <p className={classes.texte}>
        At MBE & CONSULT, we understand that data is the hidden treasure of any modern business. That's why we're proud to offer you our industry-leading expertise in Data Science, including Machine Learning and Deep Learning, to help you turn your data into opportunities and make informed decisions.        
        </p>
        <h2 className={classes.sectionsubtitle}>Machine Learning and Intelligent Predictions</h2> 
        <p className={classes.texte}>
        Machine Learning is at the heart of what we do. We develop powerful predictive models that help you anticipate trends, make data-driven decisions, and optimize your operations. Whether for product recommendation, fraud detection, sentiment analysis or any other application, we have the expertise to guide you.        
        </p>
        <h2 className={classes.sectionsubtitle}>The Future with Deep Learning</h2> 
        <p className={classes.texte}>
        Deep Learning represents the future of artificial intelligence. Our Deep Learning experts are at the forefront of this revolutionary technology. We design deep neural networks that are capable of learning from massive, complex data, opening the door to major advances in image recognition, natural language understanding and much more.        
        </p>
        <h2 className={classes.sectionsubtitle}>Personalized Solutions</h2> 
        <p className={classes.texte}>
        At MBE & CONSULT, we know that each business is unique. That's why we create personalized Data Science solutions that meet your specific needs. Whether you're a start-up looking to use data to get off to a flying start or a large enterprise looking to innovate, we have the right solution for you.        
        </p>
        <a href="#" onClick={(e) => {props.scrollToServices(e)}} className={classes.button}>Contact Us Today</a>
        <p className={classes.texte}>
        If you're ready to harness the power of data, machine learning, and deep learning to drive your business growth, we're here to help. At MBE & CONSULT, we are passionate about Data Science, and we are ready to work with you to realize your vision.        
        </p>
        </div>
    }

    return (
        <section className={classes.section}>
            <div className={classes.blocImages}>
                {listImages.map((item, number) => {
                    return <img key={item[0]} src={item[1]} alt={item[0]} className={item[2]} />
                })}
            </div>
            {text}

        </section>
    )
}