import classes from './Bloc.module.css'
import ArrowBlue from '../images/arrow.png'
import Arrow from '../images/arrowhite.png'

export default function BlocService(props) {
    // console.log(props.color)

    let arrow = ""
    if (props.color === "linear-gradient(to right bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.95))") {
        arrow = <img src={ArrowBlue} alt="arrow" className={classes.arrow} />
    } else {
        arrow = <img src={Arrow} alt="arrow" className={classes.arrow} />
    }

    return (
        <div className={classes.bloc} style={{ backgroundImage: props.color }}>
            <img src={props.img} alt={props.txt} className={classes.servicesPic} />
            <h3 className={classes.serviceTxt} style={{ color: props.color === "linear-gradient(to right bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.95))" ? "rgba(23, 34, 67, 1)" : "white", textDecoration:"none" }} >{props.txt}</h3>
            {arrow}
        </div>
    )
}