import classes from './BlocSolutions2.module.css'
import pdfToExcel from '../images/pdf to excel.png'

export default function BlocSolutions2(props) {
    let text
    if (props.language === "fr") {
        text =  <div className={classes.blocText}>
        {/* <h2 className={classes.sectionsubtitle}>Transformez Votre Entreprise avec Notre Expertise en Data Science</h2> */}
        <p className={classes.texte}>
        Chez MBE & CONSULT, notre engagement envers l'excellence dans la lecture automatique de documents et le traitement de données est au cœur de notre mission. Forts de notre expérience et de notre expertise approfondie, nous avons révolutionné la manière dont les entreprises gèrent et exploitent leurs informations cruciales.            
        </p>
        <h2 className={classes.sectionsubtitle}>Lecture Automatique de Documents</h2>
        <p className={classes.texte}>
        Notre technologie de pointe en matière de lecture automatique de documents repose sur des années de recherche et de développement. Elle nous permet de traiter une variété de formats de documents, du texte imprimé aux écritures manuscrites, en passant par les fichiers PDF, les images scannées et bien plus encore. Grâce à des algorithmes d'apprentissage automatique de pointe, notre système est capable de reconnaître, extraire et structurer automatiquement les données, éliminant ainsi l'inefficacité et les erreurs humaines.            
        </p>
        <h2 className={classes.sectionsubtitle}>Traitement de Données</h2>
        <p className={classes.texte}>
        La lecture automatique de documents n'est que la première étape. Nous allons au-delà en offrant des capacités de traitement de données avancées. Nos solutions sont conçues pour répondre aux besoins spécifiques de votre entreprise, que ce soit pour la gestion des factures, des contrats, des formulaires, des dossiers médicaux ou tout autre type de document. Nous pouvons extraire des informations pertinentes, les valider, les normaliser et les intégrer de manière transparente dans vos systèmes existants.            
        </p>
        <h2 className={classes.sectionsubtitle}>Avantages clés</h2>
        <ul style={{margin:"0px"}} className={classes.texte}>
            <li><b>Gain de Temps :</b> En automatisant la lecture de documents et le traitement de données, vous économisez des heures de travail manuel fastidieux.</li>
            <li><b>Précision :</b> Nos systèmes sont conçus pour minimiser les erreurs humaines, garantissant ainsi l'exactitude des données extraites.</li>
            <li><b>Scalabilité :</b> Notre technologie est hautement évolutive, s'adaptant à la croissance de votre entreprise sans compromettre la performance.</li>
            <li><b>Sécurité :</b> Nous accordons une importance primordiale à la sécurité des données, garantissant que vos informations sensibles sont protégées. </li>
            <li><b>Personnalisation :</b> Nous personnalisons nos solutions pour répondre aux besoins spécifiques de chaque client, garantissant ainsi une expérience sur mesure.</li>
        </ul>
        <p className={classes.texte}>
        Que vous soyez une entreprise du secteur de la finance, de la santé, de la logistique, ou de tout autre domaine, notre expertise dans la lecture automatique de documents et le traitement de données peut vous aider à optimiser vos opérations, à améliorer la productivité et à prendre des décisions éclairées basées sur des données précises et fiables.            
        </p>
        <a href="#" onClick={(e) => {props.scrollToServices(e)}} className={classes.button}>Contactez-Nous Aujourd'hui</a>
        <p className={classes.texte}>
        Contactez-nous dès aujourd'hui pour découvrir comment MBE & CONSULT peut transformer votre manière de gérer et d'utiliser vos informations grâce à notre technologie de pointe. Nous sommes prêts à relever tous les défis et à vous aider à prospérer dans un monde de plus en plus axé sur les données.            
        </p>
        </div>
    } else if (props.language === "en") {
        text =  <div className={classes.blocText}>
        {/* <h2 className={classes.sectionsubtitle}>Transformez Votre Entreprise avec Notre Expertise en Data Science</h2> */}
        <p className={classes.texte}>
        At MBE & CONSULT, our commitment to excellence in automatic document reading and data processing is at the heart of our mission. Drawing on our experience and deep expertise, we have revolutionized the way businesses manage and leverage their critical information.            
        </p>
        <h2 className={classes.sectionsubtitle}>Automatic Document Reading</h2>
        <p className={classes.texte}>
        Our cutting-edge automatic document reading technology is built on years of research and development. It allows us to process a variety of document formats, from printed text to handwritten text, PDF files, scanned images and much more. Using cutting-edge machine learning algorithms, our system is able to automatically recognize, extract and structure data, eliminating inefficiency and human errors.            
        </p>
        <h2 className={classes.sectionsubtitle}>Data processing</h2>
        <p className={classes.texte}>
        Automatically reading documents is just the first step. We go beyond offering advanced data processing capabilities. Our solutions are designed to meet the specific needs of your business, whether for managing invoices, contracts, forms, medical records or any other type of document. We can extract relevant information, validate it, standardize it and seamlessly integrate it into your existing systems.            
        </p>
        <h2 className={classes.sectionsubtitle}>Key Benefits</h2>
        <ul style={{margin:"0px"}} className={classes.texte} >
            <li><b>Time saving:</b> By automating document reading and data processing, you save hours of tedious manual work.</li>
            <li><b>Precision:</b> Our systems are designed to minimize human error, ensuring the accuracy of the extracted data.</li>
            <li><b>Scalability:</b> Our technology is highly scalable, adapting to your business growth without compromising performance.</li>
            <li><b>Security:</b> We place paramount importance on data security, ensuring that your sensitive information is protected. </li>
            <li><b>Personalization:</b> We personalize our solutions to meet the specific needs of each client, ensuring a tailor-made experience.</li>
        </ul>
        <p className={classes.texte}>
        Whether you are a company in the financial sector, healthcare, logistics, or any other field, our expertise in automatic document reading and data processing can help you optimize your operations, improve productivity and make informed decisions based on accurate and reliable data.            
        </p>
        <a href="#" onClick={(e) => {props.scrollToServices(e)}} className={classes.button}>Contact Us Today</a>
        <p className={classes.texte}>
        Contact us today to find out how MBE & CONSULT can transform the way you manage and use your information with our cutting-edge technology. We're ready to take on any challenge and help you thrive in an increasingly data-driven world.           
        </p>
        </div>
    }

    return (
        <section className={classes.section}>
            <div className={classes.blocImage}>
            <img src={pdfToExcel} alt="pdf to excel" className={classes.image} />
            </div>
            {text}
        </section>

    )
}