import { useEffect, useReducer, useRef, useState, forwardRef } from 'react'
import classes from './Contact.module.css'
import { Form, useSubmit, useLocation } from 'react-router-dom'
import useContact from '../hooks/use-contact'
import valide from '../images/valide.png'
import ReCAPTCHA from 'react-google-recaptcha'


const Reducer = (state, action) => {
    if (action.type === "name") {
        if (action.validity === "invalid") {
            return { ...state, inputNameValid: classes.invalid}
        } else {
            return { ...state, inputNameValid: ''}
        }
    }
    if (action.type === "prenom") {
        if (action.validity === "invalid") {
            return { ...state, inputPrenomValid: classes.invalid}
        } else {
            return { ...state, inputPrenomValid: ''}
        }
    }
    if (action.type === "email") {
        if (action.validity === "invalid") {
            return { ...state, inputEmailValid: classes.invalid}
        } else {
            return { ...state, inputEmailValid: ''}
        }
    }
    if (action.type === "objet") {
        if (action.validity === "invalid") {
            return { ...state, inputObjetValid: classes.invalid}
        } else {
            return { ...state, inputObjetValid: ''}
        }
    }
    if (action.type === "message") {
        if (action.validity === "invalid") {
            return { ...state, inputMessageValid: classes.invalid}
        } else {
            return { ...state, inputMessageValid: ''}
        }
    }
    if (action.type === "recaptcha") {
        if (action.validity === "invalid") {
            return { ...state, recaptchaTouched: true, recaptchaResponse: ''}
        } else {
            return { ...state, recaptchaTouched: true, recaptchaResponse: action.response}
        }
    }

}


const Contact = forwardRef((props, ref) => {
    const location = useLocation()
    // let url = location.pathname

    // const message = useActionData()
    // const submit = useSubmit()
    const recaptchaRef = useRef(null)
    const [state, dispatch] = useReducer(Reducer, { inputNameValid: '', inputPrenomValid: '', inputEmailValid:'', inputObjetValid:'', inputMessageValid:'', recaptchaTouched: false, recaptchaResponse: ''});
    const [cardPosition, setCardPosition] = useState("front")
    const [frontText, setFrontText] = useState("")
    // let frontText
    // const frontText = message ? message.message : ""
    const handleRecaptchaChange = (response) => {
        if (response) {
            dispatch({type:"recaptcha", "validity": "valid", response: response})
        } else {
            dispatch({type:"recaptcha", "validity": "invalid"})
        }
      };
    
    const resetRecaptcha = () => {
        recaptchaRef.current.reset(); // Reset the reCAPTCHA widget
    };

    const {
        handleLoseFocus:handleLoseFocusName, 
        resetElement: resetName,
        onChange:onChangeName, 
        elementValid: nameValid, 
        isTouched: isTouchedName,
        element: elementName
    } = useContact(element => element.trim() !== "")

    const {
        handleLoseFocus:handleLoseFocusPrenom, 
        resetElement: resetPrenom,
        onChange:onChangePrenom, 
        elementValid: prenomValid, 
        isTouched: isTouchedPrenom,
        element: elementPrenom
    } = useContact(element => element.trim() !== "")

    const {
        handleLoseFocus:handleLoseFocusEmail, 
        resetElement: resetEmail,
        onChange:onChangeEmail, 
        elementValid: emailValid, 
        isTouched: isTouchedEmail,
        element: elementEmail
    } = useContact(element => element.includes("@"))

    const {
        handleLoseFocus:handleLoseFocusObjet, 
        resetElement: resetObjet,
        onChange:onChangeObjet, 
        elementValid: objetValid, 
        isTouched: isTouchedObjet,
        element: elementObjet
    } = useContact(element => element.trim() !== "")

    const {
        handleLoseFocus:handleLoseFocusMessage, 
        resetElement: resetMessage,
        onChange:onChangeMessage, 
        elementValid: messageValid, 
        isTouched: isTouchedMessage,
        element: elementMessage
    } = useContact(element => element.trim() !== "")



    useEffect(() => {
        if (nameValid !== isTouchedName && state.inputNameValid !== classes.invalid && isTouchedName !== false) {
            dispatch({type:"name", "validity": "invalid"})
        } else if (nameValid === isTouchedName && state.inputNameValid != '' && isTouchedName !== false) {
            dispatch({type:"name", "validity": "valid"})
        }
        if (prenomValid !== isTouchedPrenom && state.inputPrenomValid !== classes.invalid && isTouchedPrenom !== false) {
            dispatch({type:"prenom", "validity": "invalid"})
        } else if (prenomValid === isTouchedPrenom && state.inputPrenomValid !== '' && isTouchedPrenom !== false) {
                dispatch({type:"prenom", "validity": "valid"})
        }
        
        if (emailValid !== isTouchedEmail && state.inputEmailValid !== classes.invalid && isTouchedEmail !== false) {
                dispatch({type:"email", "validity": "invalid"})
        } else if (emailValid === isTouchedEmail && state.inputEmailValid !== '' && isTouchedEmail !== false) {
                dispatch({type:"email", "validity": "valid"})
        }

        if (objetValid !== isTouchedObjet && state.inputObjetValid !== classes.invalid && isTouchedObjet !== false) {
                dispatch({type:"objet", "validity": "invalid"})
        } else if (objetValid === isTouchedObjet && state.inputObjetValid !== '' && isTouchedObjet !== false) {
                dispatch({type:"objet", "validity": "valid"})
        }
        
        if (messageValid !== isTouchedMessage && state.inputMessageValid !== classes.invalid && isTouchedMessage !== false) {
            dispatch({type:"message", "validity": "invalid"})
        } else if (messageValid === isTouchedMessage && state.inputMessageValid !== '' && isTouchedMessage !== false) {
            dispatch({type:"message", "validity": "valid"})
        }
    }, [isTouchedName, nameValid, isTouchedPrenom, prenomValid,isTouchedEmail, emailValid, isTouchedObjet, objetValid,isTouchedMessage, messageValid]) //frontText
    
    

    const onSubmit = async (event) => {
        if (nameValid && prenomValid && emailValid && objetValid && messageValid && state.recaptchaResponse) {
            event.preventDefault(); 
            const formData = new FormData(event.target)
            formData.append('g-recaptcha-response', state.recaptchaResponse)
            formData.append('language', props.language)
            const response = await fetch('https://mbe-consult.vercel.app/', {
            method: "post",
            body: formData,
            }).then((response) => {
                if (!response.ok) {
                throw new Error({ message: "Désolé, une erreur s'est produite. Veuillez réessayer" }, { status: 200 });
                }
                return response.json();
            })
            .catch((error) => {
                throw new Error(error);
            });;
            console.log(response)
            const message = response.message
            resetName()
            resetPrenom()
            resetEmail()
            resetObjet()
            resetMessage()
            resetRecaptcha()
            setCardPosition("front")
            setFrontText(message)
            return
        } else {
            event.preventDefault()
            if (!nameValid) {
                dispatch({type:"name", "validity": "invalid"})
            } 
            if (!prenomValid) {
                dispatch({type:"prenom", "validity": "invalid"})
            } 
            if (!emailValid) {
                dispatch({type:"email", "validity": "invalid"})
            }
            if (!objetValid) {
                dispatch({type:"objet", "validity": "invalid"})
            } 
            if (!messageValid) {
                dispatch({type:"message", "validity": "invalid"})
            }   
            if (!state.recaptchaResponse) {
                dispatch({type:"recaptcha", "validity": "invalid"})
            }   
            return
        }
    }

    let SendButtonText
    let LabelName
    let LabelPrenom
    let LabelEmail
    let LabelObjet
    let LabelMessage
    let ErrorName
    let ErrorPrenom
    let ErrorEmail
    let ErrorObjet
    let ErrorMessage
    let ErrorCaptcha
    let ContactText
    if (props.language === "fr") {
        SendButtonText = "Envoyer"
        LabelName = "Nom"
        LabelPrenom = "Prénom"
        LabelEmail = "Email"
        LabelObjet = "Objet du message"
        LabelMessage = "Message"
        ContactText = "Contactez-nous !"
        ErrorName = "Veuillez entrer votre nom de famille"
        ErrorPrenom = "Veuillez entrer votre prénom"
        ErrorEmail = "Veuillez entrer un email correct"
        ErrorObjet = "Veuillez entrer l'objet de votre message"
        ErrorMessage = "Veuillez entrer votre message"
        ErrorCaptcha = "Veuillez répondre au reCaptcha"
    } else if (props.language === "en") {
        SendButtonText = "Send"
        LabelName = "Last name"
        LabelPrenom = "First name"
        LabelEmail = "Email"
        LabelObjet = "Object of the message"
        LabelMessage = "Message"
        ContactText = "Contact us!"
        ErrorName = "Please enter your last name"
        ErrorPrenom = "Please enter your first name"
        ErrorEmail = "Please enter correct email"
        ErrorObjet = "Please enter the subject of your message"
        ErrorMessage = "Please enter your message"
        ErrorCaptcha = "Please respond to the reCaptcha"
    }



    let contentName  = ""
    let contentPrenom  = ""
    let contentEmail  = ""
    let contentObjet  = ""
    let contentMessage  = ""
    let contentCaptcha  = ""
    if (state.inputNameValid === classes.invalid || (!nameValid && isTouchedName)) {
        contentName = <p className={classes.invalidText}>
         {ErrorName}
        </p>
    } else if (nameValid) {
        contentName = <div className={classes.validImage}>
            <img src={valide} alt="valide"/>
        </div>
    }
    if (state.inputPrenomValid === classes.invalid || (!prenomValid && isTouchedPrenom)) {
        contentPrenom = <p className={classes.invalidText}>
                {ErrorPrenom}
        </p>
    } else if (prenomValid) {
        contentPrenom = <div className={classes.validImage}>
            <img src={valide} alt="valide"/>
        </div>
    }
    if (state.inputEmailValid === classes.invalid || (!emailValid && isTouchedEmail)) {
        contentEmail = <p className={classes.invalidText}>
            {ErrorEmail}
        </p>
    } else if (emailValid) {
        contentEmail = <div className={classes.validImage}>
            <img src={valide} alt="valide"/>
        </div>
    }
    if (state.inputObjetValid === classes.invalid || (!objetValid && isTouchedObjet)) {
        contentObjet = <p className={classes.invalidText}>
            {ErrorObjet}
        </p>
    } else if (objetValid) {
        contentObjet = <div className={classes.validImage}>
            <img src={valide} alt="valide"/>
        </div>
    }
    if (state.inputMessageValid === classes.invalid || (!messageValid && isTouchedMessage)) {
        contentMessage = <p className={classes.invalidText}>
         {ErrorMessage}
        </p>
    } else if (messageValid) {
        contentMessage = <div className={classes.validImage}>
            <img src={valide} alt="valide"/>
        </div>
    }
    if (!state.recaptchaResponse && state.recaptchaTouched) {
        contentCaptcha = <p className={classes.invalidText} style={{textAlign: "center"}}>
            {ErrorCaptcha}
        </p>}

    const returnCard = (event) => {
        event.preventDefault()
        setCardPosition("back")
    }

    return (
        <div className={classes.wrap} ref={ref}>
            <div className={classes.contact}>
                    <div className={`${classes.card} ${classes.cardFront}`} style={{ transform: cardPosition === "back" ? "rotateY(-180deg)" : "rotateY(0deg)"}}>
                            {/* <p>{frontText}</p> */}
                            <a href="#" onClick={(e) => {returnCard(e)}} className={classes.button}>{ContactText}</a>
                            <p className={classes.frontText}>{frontText}</p>
                            <h1 className={classes.logo}>MBE & CONSULT</h1>
                    </div>
                    <div className={`${classes.card} ${classes.cardBack}`} style={{ transform: cardPosition === "back" ? "rotateY(0deg)" : "rotateY(180deg)"}}>
                        <h2 className={classes.messageTitle}>{ContactText}</h2>
                        <Form method='post' action='/' className={classes.form} onSubmit={onSubmit}> 
                            <div>
                                <label htmlFor="nom">{LabelName}</label>
                                <input type="text" name='nom' value={elementName} onChange={onChangeName} id='nom' onBlur={handleLoseFocusName}  className={state.inputNameValid} />
                                <div className={classes.validationFeedback}>
                                    {contentName}
                                </div>
                            </div>
                            <div className={classes.prenom}>
                                <label htmlFor="prenom">{LabelPrenom}</label>
                                <input type="text" name='prenom' value={elementPrenom} id='prenom' onChange={onChangePrenom} onBlur={handleLoseFocusPrenom} className={state.inputPrenomValid} />
                                <div className={classes.validationFeedback}>
                                    {contentPrenom}
                                </div>
                            </div>                
                            <div className={classes.email}>
                                <label htmlFor="email">{LabelEmail}</label>
                                <input type="email" name='email' id='email' value={elementEmail} onChange={onChangeEmail} onBlur={handleLoseFocusEmail} className={state.inputEmailValid} />
                                <div className={classes.validationFeedback}>
                                    {contentEmail}
                                </div>
                            </div>
                            <div className={classes.objet}>
                                <label htmlFor="objet">{LabelObjet}</label>
                                <input type="text" name='objet' id='objet' value={elementObjet} onChange={onChangeObjet} onBlur={handleLoseFocusObjet} className={state.inputObjetValid} />
                                <div className={classes.validationFeedback}>
                                    {contentObjet}
                                </div>
                            </div>
                            <div className={classes.message}>
                                <label htmlFor="message">{LabelMessage}</label>
                                <textarea id="message" name="message" rows="5" value={elementMessage} onChange={onChangeMessage} onBlur={handleLoseFocusMessage} className={state.inputMessageValid} />
                                <div className={classes.validationFeedback}>
                                    {contentMessage}
                                </div>              
                            </div>
                            <div id="captcha">
                                <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LdCivEkAAAAAKSfhXeV1cbKA7d9ACRdvyvI_rVj"
                                onChange={handleRecaptchaChange}
                                theme="light"
                                />
                                <div className={classes.validationFeedback}>
                                    {contentCaptcha}
                                </div>    
                            </div>
                            <button type='Submit'>{SendButtonText}</button>
                            <h1 className={classes.logo}>MBE & CONSULT</h1>
                        </Form>
                    </div>
                </div>
            </div>
    )

})

export default Contact

export async function action({request}) {
    const data = await request.formData();
    const method = await request.method;
    const formData = {};
    for (const [key, value] of data.entries()) {
        formData[key] = value;
    }
    const response = await fetch('http://localhost:8080/', {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      }).then((response) => {
        // console.log(response)
        if (!response.ok) {
          throw new Error({ message: "Désolé, une erreur s'est produite. Veuillez réessayer" }, { status: 200 });
        }
        return response.json();
      })
    //   .then((data) => {
    //     return data
    //   })
      .catch((error) => {
        // console.log(error, "err")
        throw new Error(error);
      });;
    // console.log(response)
    // return redirect('/')
    return response
}