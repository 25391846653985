import classes from './Histoire.module.css'
import Arts from '../images/arts et métiers.png'
import Insa from '../images/insa.png'
import Cs from '../images/cs.png'
import Agro from '../images/agro.png'
import Polytechnique from '../images/x.png'


export default function Histoire(props) {
    const listLogos = [["Arts et Métiers", Arts, "https://artsetmetiers.fr/fr"], 
    ["Insa Lyon", Insa, "https://www.insa-lyon.fr/"],
    ["CentraleSupélec", Cs, "https://www.centralesupelec.fr/"], 
    ["AgroParisTech", Agro, "https://www.agroparistech.fr/"], 
    ["Polytechnique", Polytechnique, "https://www.polytechnique.edu/"],
]
    let Text
    let titleText
    if (props.language === "fr") {
        titleText = "Notre histoire et nos valeurs"
        Text = <p className={classes.histoireTxt}>
        Chez MBE & Consult, notre histoire est façonnée par notre quête incessante de l'excellence. Depuis nos débuts, nous avons consacré notre énergie à atteindre les plus hauts niveaux de qualité, tant dans la réalisation de nos projets que dans le recrutement de nos collaborateurs. Nous croyons fermement que l'excellence est la pierre angulaire de la réussite, et c'est cette conviction qui guide chacune de nos actions.

        Nous sommes fiers de compter parmi nos rangs des collaborateurs issus des meilleures écoles d'ingénieurs françaises. Leur expertise, leur créativité et leur détermination sont les moteurs de notre succès. Ensemble, nous formons une équipe soudée, motivée par le désir d'innover, d'aller au-delà des attentes et de dépasser les défis les plus complexes.

        En tant qu'entreprise, nous sommes profondément engagés dans la promotion de l'excellence éducative et de l'égalité des chances. C'est pourquoi nous soutenons activement <a href="https://generation-prepa.com/" className={classes.gp} target="_blank">Génération Prépa</a>, un média dont la mission est de démocratiser les parcours d'excellence et de mettre en lumière les opportunités offertes par les grandes écoles d'ingénieurs. Nous croyons en l'importance de donner à chacun la possibilité de réaliser son potentiel, quelle que soit son origine ou son parcours.

        En résumé, chez MBE & Consult, nous ne nous contentons pas de rechercher l'excellence dans nos projets et nos équipes, nous nous engageons également à contribuer à un avenir où l'excellence est accessible à tous. C'est cette vision qui guide notre entreprise et qui continuera à nous inspirer dans les années à venir.            
        </p>

    } else if (props.language === "en") {
        titleText = "Our history and our values"
        Text = <p className={classes.histoireTxt}>
At MBE & Consult, our history is shaped by our relentless pursuit of excellence. Since our beginnings, we have devoted our energy to achieving the highest levels of quality, both in carrying out our projects and in recruiting our employees. We firmly believe that excellence is the cornerstone of success, and it is this belief that guides each of our actions.
We are proud to have among our ranks employees from the best French engineering schools. Their expertise, creativity and determination are the driving forces behind our success. Together, we form a united team, motivated by the desire to innovate, to go beyond expectations and to overcome the most complex challenges.
As a company, we are deeply committed to promoting educational excellence and equal opportunity. This is why we actively support <a href="https://generation-prepa.com/" className={classes.gp} target="_blank">Génération Prépa</a>,  a media whose mission is to democratize paths to excellence and to highlight the opportunities offered by major engineering schools. We believe in the importance of giving everyone the opportunity to realize their potential, regardless of their origin or background.

In summary, at MBE & Consult, we not only seek excellence in our projects and our teams, we are also committed to contributing to a future where excellence is accessible to all. It is this vision that guides our company and will continue to inspire us in the years to come.        
</p>
    }

    return (
        <div className={classes.histoire}>
            <h2 className={classes.histoireTitle}>{titleText}</h2>
            {Text}            
            <div className={classes.blocs}>
            {listLogos.map((item) => {
                return <a key={item[0]} href={item[2]}><img src={item[1]} alt={item[0]} className={classes.histoirePic} /></a>
            })}
            </div>
        </div>
    )
}