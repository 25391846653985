import ToitEtJoie from "../images/Logo-Toit-et-Joie-Poste-Habitat.png"
import BriveHabitat from "../images/BriveHabitat.png"
import SolutionData from "../images/solutiondata.png"
import Semiv from "../images/semiv.png"
import SIA from "../images/sia-new-logo.svg"
import classes from './Clients.module.css'


export default function Clients(props) {
    const listImages = [["Sia Habitat", SIA, "https://www.sia-habitat.com/"], 
    ["Semiv", Semiv, "https://www.semiv-velizy.fr/"],
    ["Solution Data", SolutionData, "https://solutiondata.fr/"], 
    ["Toit et Joie", ToitEtJoie, "https://www.postehabitat.com/Toit-et-Joie-Poste-Habitat"],
    ["Brive Habitat", BriveHabitat, "https://www.brivehabitat.fr/"], 
    
    ]
    
    let confiance
    if (props.language === "fr") {
      confiance = "Ils nous font confiance (9 entreprises en 2024)"
    } else if (props.language === "en") {
      confiance = "They trust us (9 companies in 2024)"
    }
  
    return (
        <div className={classes.clients}>
            <h2 className={classes.confiance}>{confiance}</h2>
            <div className={classes.logos}>
                {listImages.map((item) => {
                    return <a href={item[2]} key={item[0]}><img src={item[1]} alt={item[0]} className={classes.logo} /></a>
                })}
            </div>
        </div>
    )
}