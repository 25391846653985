import classes from './Spinner.module.css'; // Import your CSS file with spinner styles

const Spinner = () => {
  return (
    <div className={classes["spinner-container"]}>
      <div className={classes.spinner}></div>
    </div>
  );
};

export default Spinner;