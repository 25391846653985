import Menu from "../components/Menu"
import Header from "../components/Header"
import { useLocation } from "react-router-dom";
import headerImage from '../images/dataheader.png'
import Section1 from "../components/Section1";
import Section2 from "../components/Section2";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import { useRef } from "react";

export default function Data() {
    const location = useLocation()
    const pathSegments = location.pathname.split("/");
    const lang = pathSegments.length > 1 ? pathSegments[1] : "";
    const section1Ref = useRef(null)

    let slogan
    if (lang === "fr" || lang === "en") {
      slogan = "Data"
    } 

    const scrollToServices = (e) => {
        e.preventDefault();
        if (section1Ref.current) {
            section1Ref.current.scrollIntoView({ behavior: "smooth" });
        }
      };
    
      

    return (
        <Menu language={lang}>
            <Header isButton={false} slogan={slogan} headerImage={headerImage} language={lang} />
            <Section1 scrollToServices={scrollToServices} language={lang} />
            <Section2 scrollToServices={scrollToServices} language={lang} />
            <Contact ref={section1Ref} language={lang} />
            <Footer language={lang} />
        </Menu>
    )
}