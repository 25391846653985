import ArticleText from "../components/ArticleText";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Contact from "../components/Contact";
import { useParams, useLocation } from "react-router-dom";
import { useEffect, useState, useCallback } from "react"
import Spinner from "../components/Spinner";
import SuggestionsArticles from "../components/SuggestionsArticles";
import parse from 'html-react-parser'

export default function Article() {
    const postId = useParams()
    const [data, setData] = useState(null)
    const [dataSuggestions, setDataSuggestions] = useState(null)
    const location = useLocation()
    const pathSegments = location.pathname.split("/");
    const lang = pathSegments.length > 1 ? pathSegments[1] : "";

    let categoryId
    let suggestionsArticlesText
    if (lang === "fr") {
        categoryId = "2"
        suggestionsArticlesText = "Suggestions d'articles"
      } else if (lang === "en") {
        categoryId = "3"
        suggestionsArticlesText = "Suggested articles"
      }

    const fetchData = useCallback(async () => {
      try {
        const response = await fetch(`https://blog.mbe-consult.fr/wp-json/wp/v2/posts/${postId.id}`); //mbedbab_ http://mbe-consult.local/wp-json/wp/v2/posts/${postId.id}
        if (!response.ok) {
          throw new Error({ message: "Désolé, une erreur s'est produite. Veuillez réessayer" }, { status: response.status });
        }
        const dataReceived = await response.json();
        let mediaResponse = await fetch(`https://blog.mbe-consult.fr/wp-json/wp/v2/media/${dataReceived.featured_media}`);
        let mediaData = await mediaResponse.json();
        const fullData = {post:dataReceived, image: mediaData}
        setData(fullData)
      } catch (error) {
        throw new Error(error);
    }      
    }, [postId]) 

    useEffect(() => {
        setData(null)
        setDataSuggestions(null)
        fetchData()
      }, [fetchData]);

      const fetchDataSuggestions = useCallback(async () => {
        try {
          const response = await fetch(`https://blog.mbe-consult.fr/wp-json/wp/v2/posts?categories=${categoryId}`);
          if (!response.ok) {
            throw new Error({ message: "Désolé, une erreur s'est produite. Veuillez réessayer" }, { status: response.status });
          }
          function shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
              const j = Math.floor(Math.random() * (i + 1));
              [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
          }
          const dataReceived = await response.json();
          const filteredPosts = dataReceived.filter((post) => post.id !== postId.id);
          const shuffledPosts = shuffleArray(filteredPosts);
          const randomPosts = shuffledPosts.slice(0, 3);
          const fullData = []
          for (let datum of randomPosts) {
              if (datum.featured_media) {
                  let mediaResponse = await fetch(`https://blog.mbe-consult.fr/wp-json/wp/v2/media/${datum.featured_media}`);
                  let mediaData = await mediaResponse.json();
                  fullData.push({post:datum, image: mediaData})
              }
          }
          setDataSuggestions(fullData)
        } catch (error) {
          throw new Error(error);
      }      
      }, [postId])

      useEffect(() => {
        setTimeout(() => {
          fetchDataSuggestions()
        }, 2000)
      }, [fetchDataSuggestions]);


    return (
    <Menu language={lang}>
        {data ? [<Header key="header" slogan={parse(data.post.title.rendered)} headerImage={data.image.source_url} />,<ArticleText key="article" text={data.post.content.rendered} />] : <Spinner />}
        {dataSuggestions ? [<h2 key="suggestionTitle" style={{color:"rgba(23, 34, 67, 1)", fontSize:"2.5rem", textAlign:"center", marginTop:"10rem"}}>Suggestions d'articles</h2>, <SuggestionsArticles key="suggestions" data={dataSuggestions} language={lang} />] : [<h2 key="suggestionTitle" style={{color:"rgba(23, 34, 67, 1)", fontSize:"2.5rem", textAlign:"center", marginTop:"10rem"}}>Suggestions d'articles</h2>, <Spinner key="spinner" />]}            
        <Contact language={lang} />
        <Footer language={lang} />
    </Menu>
    )
}