import Header from "../components/Header";
import Menu from "../components/Menu";
import BlogImage from '../images/blog.png'
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";
import BlogPosts from "../components/BlogPosts";

export default function Blog() {
    const location = useLocation()
    const pathSegments = location.pathname.split("/");
    const lang = pathSegments.length > 1 ? pathSegments[1] : "";

    return (
        <Menu language={lang}>
            <Header slogan="Blog" headerImage={BlogImage} />
            <BlogPosts language={lang} />
            <Footer language={lang} />
        </Menu>
    )
}