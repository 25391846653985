import classes from "./Solutions.module.css";
import Copro from "../images/coprowhite.png";
import Pdf from "../images/lecture_documentswhite.png";
import Bloc from "./Bloc";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

export default function Solutions(props) {
  const location = useLocation()
  let data
  let SolutionsText
  if (props.language === "fr") {
    SolutionsText = "Nos solutions"
    data = [
      ["Outil de gestion pour les assemblées de copropriété", Copro, "/solutions"],
      ["Lecture automatique de documents", Pdf, "/solutions"],
    ];
  } else if (props.language === "en") {
    SolutionsText = "Our solutions"
    data = [
      ["Management tool for co-ownership meetings", Copro, "/solutions"],
      ["Automatic document reading", Pdf, "/solutions"],
    ];
  }


// backgroundColor: props.color rgba(23, 34, 67, 1)
function scrollToTop() {
  window.scrollTo(0, 0); 
}

  return (
    <div className={classes.solutions}>
      <h2 className={classes.solutionsTxt}>{SolutionsText}</h2>
      <div className={classes.blocs}>
        {data.map((item) => {
          return <Link to={location.pathname + item[2]} onClick={scrollToTop} key={item[0]}><Bloc img={item[1]} txt={item[0]} color="linear-gradient(to right bottom, rgba(23, 34, 67, 1), rgba(23, 34, 67, 0.95))" /></Link>;
        })}
      </div>
    </div>
  );
}
