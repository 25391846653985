import classes from './SuggestionsArticles.module.css'
import CardPost from './CardPost';
import Spinner from './Spinner';
import { Link } from 'react-router-dom'

export default function SuggestionsArticles(props) {
    function scrollToTop() {
        window.scrollTo(0, 0);
    }
    return (
        <section className={classes.suggestions}>
            <div className={classes.cards}>
                {props.data ? props.data.map(post => {
                    return <Link to={`/${props.language}/blog/${post.post.id}`} onClick={scrollToTop} className={classes.link} key={post.post.id}><CardPost title={post.post.title.rendered} excerpt={post.post.excerpt.rendered} image={post.image.source_url} altText={post.image.alt_text} /></Link> 
                }) : <Spinner/> }
            </div>
        </section>
    )
}