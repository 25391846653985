import classes from './SectionWeb.module.css'

export default function SectionWeb(props) {

    let text
    if (props.language === "fr") {
        text =  <div className={classes.containerTexte}>
        <h2 className={classes.sectionsubtitle}>Notre Expertise en Développement Web</h2>
        <p className={classes.sectionTexte}>Chez MBE & CONSULT, notre expertise en développement web est le moteur qui alimente des expériences en ligne exceptionnelles et des solutions numériques de pointe. Avec une équipe de développeurs web chevronnés et passionnés, nous sommes fiers de notre capacité à transformer des idées en réalité interactive.</p>
        <h2 className={classes.sectionsubtitle}>Technologie de Pointe</h2>
        <p className={classes.sectionTexte}>Notre équipe de développement web est à la pointe de la technologie. Nous maîtrisons les dernières tendances en matière de développement frontend et backend, de sorte que votre site web est non seulement à la pointe du design, mais aussi de la performance et de la sécurité.</p>
        <h2 className={classes.sectionsubtitle}>Conception Réactive</h2>
        <p className={classes.sectionTexte}>Nous comprenons l'importance d'un site web réactif. Nos sites sont conçus pour s'adapter de manière transparente à tous les appareils, que ce soit sur un ordinateur de bureau, une tablette ou un smartphone. L'expérience utilisateur est notre priorité.</p>
        <h2 className={classes.sectionsubtitle}>Personnalisation Approfondie</h2>
        <p className={classes.sectionTexte}>Chaque entreprise est unique, et nous croyons que chaque site web devrait l'être aussi. Nous travaillons en étroite collaboration avec nos clients pour comprendre leurs besoins spécifiques et personnaliser chaque élément de leur site web, de la conception à la fonctionnalité.</p>
    </div>
    } else if (props.language === "en") {
        text = <div className={classes.containerTexte}>
        <h2 className={classes.sectionsubtitle}>Our Expertise in Web Development</h2>
        <p className={classes.sectionTexte}>At MBE & CONSULT, our web development expertise is the engine that powers exceptional online experiences and cutting-edge digital solutions. With a team of experienced and passionate web developers, we pride ourselves on our ability to transform ideas into interactive reality.</p>
        <h2 className={classes.sectionsubtitle}>Advanced technology</h2>
        <p className={classes.sectionTexte}>Our web development team is at the cutting edge of technology. We master the latest trends in frontend and backend development, so your website is not only cutting edge in design, but also in performance and security.</p>
        <h2 className={classes.sectionsubtitle}>Responsive Design</h2>
        <p className={classes.sectionTexte}>We understand the importance of a responsive website. Our sites are designed to adapt seamlessly to all devices, whether on a desktop, tablet or smartphone. User experience is our priority.</p>
        <h2 className={classes.sectionsubtitle}>Deep Customization</h2>
        <p className={classes.sectionTexte}>Every business is unique, and we believe every website should be too. We work closely with our clients to understand their specific needs and customize every element of their website, from design to functionality.</p>
    </div>
    }

    return (
        <section className={classes.section}>
            {text}
            <img src={props.image} alt="web logo" className={classes.sectionImage} />
        </section>
    )
}