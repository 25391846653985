import { useRef } from "react";
import video from '../images/assemblee.mp4'
import classes from './BlocSolutions.module.css'

export default function BlocSolutions(props) {

    const videoRef = useRef(null)

    let text
    if (props.language === "fr") {
        text =  <div className={classes.blocText}>
        <h2 className={classes.sectionsubtitle}>Notre Solution de Gestion des Assemblées Générales de Copropriétés AGC+ : L'Efficacité à Votre Service</h2>
        <p className={classes.texte}>
        Chez MBE & CONSULT, nous comprenons à quel point la gestion des assemblées générales de copropriétés peut être un défi complexe et chronophage pour les syndics et les gestionnaires immobiliers. C'est pourquoi nous avons développé une solution innovante qui simplifie et optimise chaque aspect de ce processus crucial.            
        </p>
        <p className={classes.texte}>
        Notre solution de gestion des assemblées générales est conçue pour offrir à nos clients la tranquillité d'esprit et l'efficacité dont ils ont besoin pour gérer efficacement les réunions de copropriétaires. Voici comment notre solution peut transformer votre gestion immobilière :            
        </p>
        <p className={classes.texte}>
        <b>1. Automatisation des Tâches Récurrentes :</b> Notre plateforme intelligente automatise de nombreuses tâches liées à la préparation et à la gestion des assemblées générales. Cela inclut la création d'ordres du jour, la collecte des procurations électroniques, la gestion des votes et la génération du procès verbal.            
        </p>
        <p className={classes.texte}>
        <b>2. Personnalisation :</b> Chaque copropriété est unique, c'est pourquoi notre solution est entièrement personnalisable pour répondre aux besoins spécifiques de votre copropriété. Vous pouvez configurer des paramètres et des règles sur mesure pour chaque réunion.            
        </p>
        {/* <p className={classes.texte}>
        <b>3. Communication Simplifiée :</b> Notre système facilite la communication avec les copropriétaires en leur permettant d'accéder aux informations essentielles en ligne. Ils peuvent consulter les documents, poser des questions et voter confortablement depuis chez eux.            
        </p> */}
        <p className={classes.texte}>
        <b>3. Sécurité des Données :</b> La sécurité des données est notre priorité. Vos informations sensibles sont stockées de manière sécurisée et confidentielle, conformément aux normes de sécurité les plus élevées.            
        </p>
        <p className={classes.texte}>
        <b>4. Rapports et Analytique :</b> Notre solution fournit des rapports détaillés pour une meilleure compréhension de chaque assemblée générale. Vous pouvez également analyser les tendances pour prendre des décisions éclairées.            
        </p>
        <p className={classes.texte}>
        <b>5. Support Expert :</b> Notre équipe dévouée de professionnels est toujours prête à vous assister à chaque étape du processus. Nous sommes là pour répondre à vos questions et résoudre les problèmes rapidement.            
        </p>
        <p className={classes.texte}>
        Lorsque vous choisissez MBE & CONSULT, vous choisissez l'efficacité, la transparence et la simplicité pour la gestion des assemblées générales de copropriétés. Nous sommes fiers de faire partie de la transformation numérique de l'industrie immobilière en offrant des solutions innovantes qui facilitent votre travail quotidien.            
        </p>
        <a href="#" onClick={(e) => {props.scrollToServices(e)}} className={classes.button}>Contactez-Nous Aujourd'hui</a>
        <p className={classes.texte}>
        N'attendez plus pour améliorer la gestion de vos assemblées générales de copropriétés. Contactez-nous dès aujourd'hui pour en savoir plus sur notre solution et découvrir comment nous pouvons vous aider à optimiser votre gestion immobilière.            
        </p>
        </div>
    } else if (props.language === "en") {
        text =  <div className={classes.blocText}>
        <h2 className={classes.sectionsubtitle}>Our Solution for Managing General Meetings of Co-ownerships AGC+: Efficiency at Your Service</h2>
        <p className={classes.texte}>
        At MBE & CONSULT, we understand how managing general meetings of co-ownerships can be a complex and time-consuming challenge for trustees and property managers. That's why we've developed an innovative solution that simplifies and optimizes every aspect of this crucial process.        </p>
        <p className={classes.texte}>
        Our general meeting management solution is designed to provide our clients with the peace of mind and efficiency they need to effectively manage co-owner meetings. Here's how our solution can transform your property management:        </p>
        <p className={classes.texte}>
        <b>1. Automation of Recurring Tasks:</b> Our intelligent platform automates many tasks related to the preparation and management of general meetings. This includes creating agendas, collecting electronic proxies, and managing votes.            
        </p>
        <p className={classes.texte}>
        <b>2. Personalization:</b> Each co-ownership is unique, which is why our solution is fully customizable to meet the specific needs of your co-ownership. You can configure tailor-made settings and rules for each meeting.            
        </p>
        {/* <p className={classes.texte}>
        <b>3. Simplified Communication:</b> Our system facilitates communication with co-owners by allowing them to access essential information online. They can view documents, ask questions and vote comfortably from home.            
        </p> */}
        <p className={classes.texte}>
        <b>3. Data Security:</b> Data security is our priority. Your sensitive information is stored securely and confidentially, in accordance with the highest security standards.           
        </p>
        <p className={classes.texte}>
        <b>4. Reporting and Analytics:</b> Our solution provides detailed reports for a better understanding of each general meeting. You can also analyze trends to make informed decisions.            
        </p>
        <p className={classes.texte}>
        <b>5. Expert Support:</b> Our dedicated team of professionals is always ready to assist you every step of the way. We're here to answer your questions and resolve issues quickly.            
        </p>
        <p className={classes.texte}>
        When you choose MBE & CONSULT, you choose efficiency, transparency and simplicity for the management of general meetings of co-ownerships. We are proud to be part of the digital transformation of the real estate industry by offering innovative solutions that make your daily work easier.            
        </p>
        <a href="#" onClick={(e) => {props.scrollToServices(e)}} className={classes.button}>Contact Us Today</a>
        <p className={classes.texte}>
        Don't wait any longer to improve the management of your general meetings of co-ownerships. Contact us today to learn more about our solution and find out how we can help you optimize your property management.            
        </p>
        </div>
    }


    return (
        <section className={classes.section}>
            {text}
            <video ref={videoRef} className={classes.video} autoPlay loop muted >
                <source src={video} type="video/mp4" />
            </video>

        </section>
    )
}

