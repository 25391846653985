import classes from "./ArticleText.module.css"
import parse from 'html-react-parser'

export default function ArticleText(props) {
    const options = {
        decodeEntities: true, 
      };

    
    return (
        <section className={classes.article}>
            {parse(props.text, options)}
        </section>
    )
}