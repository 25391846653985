import Menu from "../components/Menu"
import Header from "../components/Header"
import { useLocation } from "react-router-dom";
import headerImage from '../images/header2.png'
import Footer from "../components/Footer"
import Contact from "../components/Contact";
import SectionImmo from "../components/SectionImmo";
import { useRef } from "react";

export default function Immobilier() {
    const location = useLocation()
    const pathSegments = location.pathname.split("/");
    const lang = pathSegments.length > 1 ? pathSegments[1] : "";
    const sectionImmo = useRef(null)

    let slogan
    if (lang === "fr") {
      slogan = "Immobilier"
    } else if (lang === "en") {
      slogan = "Real Estate"
    }

    const scrollToServices = (e) => {
        e.preventDefault();
        if (sectionImmo.current) {
            sectionImmo.current.scrollIntoView({ behavior: "smooth" });
        }
      };


    return (
      <>
        <Menu language={lang} >
            <Header isButton={false} slogan={slogan} headerImage={headerImage} language={lang} />
            <SectionImmo scrollToServices={scrollToServices} language={lang} />
            <Contact ref={sectionImmo} language={lang} />
            <Footer language={lang} />
        </Menu>
      </>
    )
}